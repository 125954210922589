import React from 'react'
import './PrimaryHeading.scss'


const PrimaryHeading = ({ PrimaryHeading }) => {
    return (
        <div className='primary-heading-container'>
            {PrimaryHeading}
        </div>
    )
}

export default PrimaryHeading
