import React, { useEffect } from "react";

// ANT-d :
import { Button, Form, Input } from "antd";

// Components :
import { TagLine } from "../Components";

// Redux :
import { useDispatch } from "react-redux";

// Services :
import AuthService from "../../../Services/auth.services";

// CSS :
import "./ConfirmPassword.scss";


const ConfirmPassword = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Jaydad | New-Password';
  }, [dispatch])

  const handleConfirmPassword = (e) => {
    AuthService.newPassword();
  };

  return (
    <>
      <div className="confirm_container">
        <TagLine />
        <Form
          layout="vertical"
          onFinish={handleConfirmPassword}
          className="confirm_form"
        >
          <h2>Create Your New Password</h2>

          <Form.Item label="Enter OTP" name="OTP" style={{ width: '100%' }}>
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item label="New Password" name="password" className="registration-field"
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                min: 4, message: 'Password must contain at least 4 characters with at last'
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item label="Confirm Password" name="confirmPassword" dependencies={['password']}
            style={{ width: '100%' }}
            rules={[{ required: true },

            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The confirm password that you entered do not match!'));
              },
            }),
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Button htmlType="submit" className="primary-btn">Submit</Button>
        </Form>
      </div>
    </>
  );
};

export default ConfirmPassword;
