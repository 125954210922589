import { combineReducers } from "@reduxjs/toolkit";
import orderReducer from "./Catalog/orderSlice";
import signInReducer from "./Auth/SignInSlice";
import signUpReducer from "./Auth/SignUpSlice";
import attributesReducer from "./Admin/Attributes";
import categoryReducer from "./Admin/CategorySlice";
import productReducer from "./Catalog/ProductSlice";
import commonReducer from "./commonSlice";

const rootReducer = combineReducers({
    signIn: signInReducer,
    signUp: signUpReducer,
    order: orderReducer,
    common: commonReducer,
    product: productReducer,
    category: categoryReducer,
    attributes: attributesReducer,
});

export default rootReducer;
