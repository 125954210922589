import React from 'react'

// ANT-D :
import { Badge, Button } from 'antd';

// Chart.Js : 
import { Line } from "react-chartjs-2";
import {
    Title,
    Legend,
    Tooltip,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    Chart as ChartJS,
} from 'chart.js';

// CSS :
import './DashboardReviewChart.scss'

// Registring Chart :
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DashboardReviewChart = () => {
    let data = {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
            {
                label: "# of Votes",
                data: [0, 150, 350, 200, 280, 450],
                borderWidth: 2,
                borderColor: "#1877f2",
                backgroundColor: "#f86600",
                pointBorderColor: "transparent",
                tension: 0.4
            },
            {
                label: "# of Votes",
                data: [0, 300, 100, 400,],
                borderWidth: 2,
                borderDash: [2, 2],
                borderColor: "#FCD117",
                backgroundColor: "#F2F3F5;",
                pointBorderColor: "transparent",
                tension: 0.4
            }
        ]
    }
    let options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 13
                    },
                    color: "rgba(0, 0, 0, 0.4)"
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    font: {
                        size: 13
                    },
                    color: "rgba(0, 0, 0, 0.4)",
                    callback: function callback(value, index, values) {
                        return value + "k";
                    }
                },
                grid: {
                    color: "#B5BFCE",
                    drawBorder: true
                },
                border: {
                    dash: [2, 2]
                }
            }
        }
    }

    return (
        <div className='lineChart'>
            <div className="chartHeader">
                <div className='messages'>No. Of Messages</div>
                <div className='checkMessages'>Message Sent : 1000</div>
                <div className='checkMessages'>Sending Failed : 10</div>
                <Badge color='#f86600' text="Sent" style={{ marginBottom: "0px" }} />
                <Badge color='#01347b' text="Failed" style={{ marginBottom: "0px" }} />
                <Button type="primary" style={{ marginBottom: "0px" }}>See All Report</Button>
            </div>
            <div className="chart">
                <Line options={options} data={data} />
            </div>
        </div>
    )
}

export default DashboardReviewChart