import http from '../http.service';
import { toast } from 'react-toastify';
import Promisable from '../promisable.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { orderActions } from '../../Redux/Catalog/orderSlice';
import { productActions } from '../../Redux/Catalog/ProductSlice';

const getHeaders = () => {
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `${token}`,
    };
    return headers;
};

const orderServices = {
    getOrders: async (dispatch) => {
        let headers = getHeaders();
        let URL = `/products/api/v1/manage-orders/get-new-orders`;
        const [success, error] = await Promisable.asPromise(http.get(URL, headers));

        if (success) {
            const successData = success.data.responseData.data.responseData.data;
            console.log("-----", success)
            // dispatch(orderActions.setOrderList(successData))
            // dispatch(categoryActions.setCategoriesList(successData))
            // dispatch(categoryActions.setIsLoading(false))
        }
        if (error) {
            console.log("============>", error);
            // dispatch(categoryActions.setIsLoading(false))
        }
        return [success, error];
    },
}
export default orderServices;