import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    orderList: {},
}

export const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setOrderList: (state, action) => {
            state.orderList = action.payload;
        },
    }
});

const orderReducer = orderSlice.reducer;
export const orderActions = orderSlice.actions;

export default orderReducer;