import React, { Suspense } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

// Layouts :
import { AppLayout, AuthLayout } from "../Layouts";

// Pages :
import {
    Login,
    Register,
    ConfirmPassword,
    ForgotPassword,
} from "../Pages/Auth";

// Components :
import { Loader } from "../Components";

// Constants :
import APPRoute from "../Constants/RoutesConstant";

// Helpers :
import { ToastContainer } from "react-toastify";

// Redux :
import { useSelector } from "react-redux";


const AppRoutes = () => {
    const { user } = useSelector((state) => state.signIn);

    const SuspenseWrappter = (LazyComponent, isFullHeight) => (
        <Suspense fallback={<Loader isFullHeight={isFullHeight} />}>
            <LazyComponent />
        </Suspense>
    );

    const ProtectedRoute = () => {
        if (user) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Routes>
                <Route element={<AuthLayout />}>
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="new-password" element={<ConfirmPassword />} />
                    <Route path="*" element={<Navigate to="/login" replace />} />
                </Route>
                <Route element={<AppLayout />}>
                    {APPRoute.map((data, index) => (
                        <Route
                            key={index}
                            path={data.path}
                            element={
                                ProtectedRoute() ?
                                    SuspenseWrappter(data.element) :
                                    (<Navigate to="/login" replace />)
                            }
                        />
                    ))}

                </Route>
            </Routes>
        </>
    );
};

export default AppRoutes;
