import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    pageTitle: 'Dashboard'
}

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload;
        },
    }
});

const commonReducer = commonSlice.reducer;
export const commonActions = commonSlice.actions;

export default commonReducer;