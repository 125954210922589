import React from 'react'

// CSS :
import './TagLine.scss'


const TagLine = () => {
    return (
        <div className="tag-line-container">
            <h1>Jaydad</h1>
            <p>Jaydad is an e-commerce website that helps you purchase your desired
                products.</p>
        </div>
    )
}

export default TagLine
