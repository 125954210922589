import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// AND-D :
import { Layout, Menu } from "antd";
import MenuIcon from "../../Assets/Icons/menu.svg";

// Constants:
import { AppRoutesList } from "../../Constants";

// CSS :
import "./Sidebar.scss";

const { Sider } = Layout;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("/dashboard");

  useEffect(() => {
    let paths = location.pathname;
    setSelectedKey(paths);
  }, [location.pathname]);

  const handleMenuClick = (key) => {
    navigate(key);
  };
  const handleToggle = () => {
    setCollapsed(!collapsed);

    if (!collapsed) {
      const element = document.querySelector(".ant-modal");
      element?.style.setProperty("margin-left", "80px");
    } else {
      const element = document.querySelector(".ant-modal");
      element?.style.setProperty("margin-left", "240px");
    }
  };

  return (
    <Sider
      width="250px"
      breakpoint="lg"
      className="sider"
      collapsedWidth="80px"
      collapsed={collapsed}
      onCollapse={setCollapsed}
    >
      <div className="sider-header">
        <div
          className={`toggle-menu ${!collapsed ? "" : "fix-collapse"}`}
          onClick={handleToggle}
        >
          <img src={MenuIcon} alt="" />
        </div>
        {/* <div className={`app-logo ${!collapsed ? "" : "hidden"}`}>
                    <img src={MenuIcon} alt="" />
                </div> */}
      </div>
      <Menu
        mode="inline"
        items={AppRoutesList}
        className="sider-menu"
        defaultOpenKeys={["/dashboard"]}
        selectedKeys={[selectedKey]}
        onClick={({ key }) => handleMenuClick(key)}
      />
    </Sider>
  );
};

export default Sidebar;
