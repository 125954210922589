import { Outlet, useLocation } from "react-router-dom";

import { Header, Sidebar } from "../Components";

import "./layouts.scss";


const AppLayout = () => {
    return (
        <div className="sidebar-container">
            <Sidebar />
            <div className="right-pages">
                <Header />
                <div className="app-routes">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default AppLayout;
