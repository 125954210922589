import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    productList: [],
    productDetails: [],
    countOfAllProducts: null,
}

export const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setProductList: (state, action) => {
            state.productList = action.payload;
        },
        setCountOfAllProducts: (state, action) => {
            state.countOfAllProducts = action.payload;
        },
        setProductDetails: (state, action) => {
            state.productDetails = action.payload;
        }
    }
});

const productReducer = productSlice.reducer;
export const productActions = productSlice.actions;

export default productReducer;