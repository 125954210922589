import React from 'react';

// ANT-D :
import { Layout, Menu, Button } from 'antd';

import { MdMarkEmailRead } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";

// import { MailFilled, UnorderedListOutlined } from '@ant-design/icons';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAddressBook, faFileInvoice, faScaleUnbalancedFlip, faUsers, faRightToBracket, faLink } from '@fortawesome/free-solid-svg-icons';

// import 'DashboardQuickLinks.scss'

const { Sider } = Layout;


const DashboardQuickLinks = () => {
    // const selectedKeys = pathname.substr(1);
    // const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <div className="gx-inner-sidebar-content gx-inner-sidebar-widget">
            <div className='gx-inner-sidebar-widget-head gx-mb-2'>
                <span className="gx-text-secondary gx-mr-2">
                    {/* <FontAwesomeIcon icon={faLink} /> */}
                </span> Quick Links
            </div>

            <div className="gx-d-flex">
                <Button
                    className="btn-left-secondary-ico gx-text-black gx-font-weight-bold gx-w-50"
                    type="default"
                    icon={<FaPhoneSquareAlt />}
                >
                    Contact
                </Button>
                <Button
                    className="btn-left-secondary-ico gx-text-black gx-font-weight-bold gx-w-50"
                    type="default"
                    icon={<MdMarkEmailRead />}
                >
                    Send
                </Button>
            </div>

            {/* <div className='gx-small-height-menu'>
                <Menu
                    mode="inline"
                    defaultOpenKeys={[defaultOpenKeys]}
                    selectedKeys={[selectedKeys]}
                    theme={'lite'}
                    style={{ backgroundColor: "#F2F3F7" }}
                >

                    <Menu.Item key="importcontacts">
                        <Link to="/dashboard">
                            <span className="anticon">
                                <FontAwesomeIcon icon={faAddressBook} />
                            </span>
                            <span><IntlMessages id="sidebar.importcontacts" /></span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="invoices">
                        <Link to="/dashboard">
                            <span className="anticon">
                                <FontAwesomeIcon icon={faFileInvoice} />
                            </span>
                            <span><IntlMessages id="sidebar.invoices" /></span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="lowbalance">
                        <Link to="/dashboard">
                            <span className="anticon">
                                <FontAwesomeIcon icon={faScaleUnbalancedFlip} />
                            </span>
                            <span><IntlMessages id="sidebar.lowbalance" /></span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="subaccounts">
                        <Link to="/dashboard">
                            <span className="anticon">
                                <FontAwesomeIcon icon={faUsers} />
                            </span>
                            <span><IntlMessages id="sidebar.subaccounts" /></span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="autotopup">
                        <Link to="/dashboard">
                            <span className="anticon">
                                <FontAwesomeIcon icon={faRightToBracket} />
                            </span>
                            <span><IntlMessages id="sidebar.autotopup" /></span>
                        </Link>
                    </Menu.Item>

                </Menu>
            </div> */}
        </div>
    );
}
export default DashboardQuickLinks