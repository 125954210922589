import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    attributesList: null,
    isCreateUpdateLoading: false,
}

export const attributesSlice = createSlice({
    name: "attributes",
    initialState,
    reducers: {
        fetchStart: (state) => {
            state.isLoading = true;
        },
        fetchSuccess: (state, action) => {
            state.isLoading = false;
            state.attributesList = action.payload;
        },
        fetchError: (state) => {
            state.isLoading = false;
        },
        addAttributeStart: (state) => {
            state.isCreateUpdateLoading = true;
        },
        addAttributeSuccess: (state) => {
            state.isCreateUpdateLoading = false;
        },
        addAttributeError: (state) => {
            state.isCreateUpdateLoading = false;
        },
    }
});

const attributesReducer = attributesSlice.reducer;
export const attributesActions = attributesSlice.actions;

export default attributesReducer;