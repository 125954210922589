import http from './http.service';
import { toast } from 'react-toastify';
import Promisable from './promisable.service';
import { signInActions } from '../Redux/Auth/SignInSlice';
import { signUpActions } from '../Redux/Auth/SignUpSlice';


const AuthService = {

    login: async (values, dispatch, navigate) => {
        dispatch(signInActions.fetchStart());
        let URL = `/auth/api/v1/auth/login`;
        const [success, error] = await Promisable.asPromise(http.post(URL, values));

        if (success) {
            toast.success(success.data.statusMessage);
            const successData = success.data.data;
            dispatch(signInActions.fetchSuccess(successData));
            navigate('/dashboard');
        }

        if (error) {
            toast.error(error.response.data.message);
            dispatch(signInActions.fetchError(error));
        }

        return [success, error];
    },

    register: async (values, dispatch, navigate) => {
        dispatch(signUpActions.fetchStart());
        let URL = `/auth/api/v1/auth/register`;
        const [success, error] = await Promisable.asPromise(http.post(URL, values));

        if (success) {
            toast.success(success.data.responseData.data);
            navigate('/login');
            const successData = success.data;
            dispatch(signUpActions.fetchSuccess(successData));
        }

        if (error) {
            console.log("-=-=-", error)
            const errorMessage = error.response.data.responseData.message;
            toast.error(errorMessage);
            dispatch(signUpActions.fetchError(errorMessage));
        }

        return [success, error];
    },

    forgotPassword: async (values, dispatch, data, navigate) => {
        // dispatch(signUpActions.fetchStart());
        let URL = `/auth/api/v1/users/forgot-password?email=${values}`;
        const [success, error] = await Promisable.asPromise(http.post(URL, values));

        if (success) {
            console.log("-------->", success);
            // const successData = success.data.message;
            // dispatch(signUpActions.fetchSuccess(successData));
        }

        if (error) {
            console.log("-------->", error);

            // let errorMessage = error.response.data.message;
            // dispatch(signUpActions.fetchError(errorMessage));
        }

        return [success, error];
    },

    newPassword: async (values, dispatch, data, navigate) => {
        // dispatch(signUpActions.fetchStart());

        let URL = `/auth/api/v1/users/save-reset-password?&newPassword=123456&confirmNewPassword=1234562&otp=437429`;
        const [success, error] = await Promisable.asPromise(http.post(URL, values));

        if (success) {
            console.log("-------->", success);
            // const successData = success.data.message;
            // dispatch(signUpActions.fetchSuccess(successData));
        }

        if (error) {
            console.log("-------->", error);

            // let errorMessage = error.response.data.message;
            // dispatch(signUpActions.fetchError(errorMessage));
        }

        return [success, error];
    },

    logout: (navigate) => {
        localStorage.clear();
        navigate('/login');
    },
};

export default AuthService;