import React from 'react'

// ANT-D :
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const Spinner = () => {
    return (
        <Spin className='spinner-container' indicator={<LoadingOutlined className='spin-icon' spin />} />
    )
}

export default Spinner
