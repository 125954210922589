import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

// ANT-D :
import { Button, Form, Input } from 'antd'

// Components :
import { TagLine } from '../Components'

// Services :
import AuthService from '../../../Services/auth.services'

// Redux :
import { useDispatch } from 'react-redux'

// CSS :
import './ForgotPassword.scss'


const ForgotPassword = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Jaydad | Forgot-Password';
  }, [dispatch])
  const onFinish = (values) => {
    AuthService.forgotPassword(values, dispatch);
  }
  return (
    <div className="forget_container">
      <TagLine />
      <Form
        layout='vertical'
        onFinish={onFinish}
        className='forget_form'
      >
        <h2>Forgot Password</h2>
        <p>Please enter your email address.</p>
        <Form.Item
          name="email"
          className='form-field'
          rules={[{ required: true, type: "email" }]}
        >
          <Input size='large' placeholder='Enter email address' type='text' />
        </Form.Item>
        <Button className='primary-btn' htmlType='submit'>Submit</Button>
        <Link to="/">Log in</Link>
      </Form>
    </div>
  )
}

export default ForgotPassword
