import { Button, Modal } from "antd";
import { useState, useEffect } from "react";

import { Select, Row, Col } from "antd";

// redux :
import { useDispatch, useSelector } from "react-redux";
import categoryService from "../../Services/Admin/category.service";

const { Option } = Select;

const CategoriesModal = ({ showModal, onSelection, onClose }) => {
    const dispatch = useDispatch();
    const { publicCategories } = useSelector((state) => state.category);

    const [isModalVisible, setIsModalVisible] = useState(showModal || false);

    const [categoriesLevels, setCategoriesLevels] = useState([]);
    const [categoryCurrentLevel, setCategoryCurrentLevel] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState({});

    const [categoryName, setCategoryName] = useState("");
    const [categoryId, setCategoryId] = useState(0);


    useEffect(() => {
        (async () => {
            await categoryService.getPublicCategories(dispatch, categoryId);
            setCategoriesLevels([...categoriesLevels, publicCategories])
        })()
    }, [dispatch]);

    useEffect(() => {
        let currrentSelectedCategory = null;

        const removedNextLevels = Object.keys(selectedCategories).reduce(
            (acc, key) => {
                if (key == categoryCurrentLevel) {
                    currrentSelectedCategory = selectedCategories[key];
                }

                if (key <= categoryCurrentLevel) {
                    acc[key] = selectedCategories[key];
                }

                return acc;
            },
            {}
        );

        const categoriesNameTree = Object.keys(removedNextLevels).map(
            (level, i) => {
                return removedNextLevels[i].name;
            }
        );

        if (categoriesNameTree.length)
            setCategoryName(categoriesNameTree.join(" > "));
        if (currrentSelectedCategory?.id)
            setCategoryId(currrentSelectedCategory.id);
    }, [selectedCategories]);

    const handleOk = () => {
        setIsModalVisible(false);
        onSelection(categoryId, categoryName);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        onClose();
    };

    const onChangeCategoryHandler = async (value, level) => {

        setCategoryCurrentLevel(level);
        const findCategory = categoriesLevels[level].find(
            (category) => category.id === value
        );
        setSelectedCategories(
            Object.assign({}, selectedCategories, { [level]: findCategory })
        );

        let [success, error] = await categoryService.getPublicCategories(dispatch, findCategory.id);

        const cloneArr = categoriesLevels.slice(0);
        cloneArr.splice(level + 1, cloneArr.length - (level + 1));

        const successData = success.data.responseData.data;

        if (successData.length) {
            if (categoriesLevels.length > level) {
                setCategoriesLevels([...cloneArr, successData]);
            } else {
                setCategoriesLevels([...categoriesLevels, successData]);
            }
        } else {
            setCategoriesLevels([...cloneArr]);
        }

    };

    const onSearchCategory = (value) => {
        // console.log('search:', value);
    };

    console.log("--------------->", categoriesLevels)

    return (
        <>
            <Modal
                title="Categories"
                open={isModalVisible}
                okText="Change"
                onOk={handleOk}
                onCancel={handleCancel}
            >
                {categoriesLevels.map((caregoryLevel, i) => (
                    <Row key={i} className="gx-mb-3">
                        <Col span={12} offset={6}>
                            <Select
                                showSearch
                                placeholder="Select a category"
                                optionFilterProp="children"
                                onChange={(e) => onChangeCategoryHandler(e, i)}
                                onSearch={onSearchCategory}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                style={{ width: "100%" }}
                            >
                                {caregoryLevel.map((category) => (
                                    <Option key={category.id} value={category.id}>
                                        {category.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                ))}
            </Modal>
        </>
    );
};

export default CategoriesModal;
