import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    categoriesList: null,
    categoryattribute: [],
    publicCategories: [],
    publicChildCategories: [],
    isCreateUpdateLoading: false,
}

export const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setCategoriesList: (state, action) => {
            state.categoriesList = action.payload;
        },
        setIsCreateUpdateLoading: (state, action) => {
            state.isCreateUpdateLoading = action.payload;
        },
        setCategoryattribute: (state, action) => {
            state.categoryattribute = action.payload;
        },
        setPublicCategories: (state, action) => {
            state.publicCategories = action.payload;
        },
        setPublicChildCategories: (state, action) => {
            state.publicChildCategories = action.payload;
        },

    }
});

const categoryReducer = categorySlice.reducer;
export const categoryActions = categorySlice.actions;

export default categoryReducer;