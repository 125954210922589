import React from "react";

// Assets | ICONS :
import { BiSupport } from "react-icons/bi";
import { GrCatalog } from "react-icons/gr";
import { BsFlagFill } from "react-icons/bs";
import { BiSolidCog } from "react-icons/bi";
import { RiVideoFill } from "react-icons/ri";
import { FaProductHunt } from "react-icons/fa";
import { MdPersonSearch } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { PiUsersThreeFill } from "react-icons/pi";
import { BiSolidDashboard } from "react-icons/bi";
import { BiSolidHomeSmile } from "react-icons/bi";
import { RiFolderHistoryFill } from "react-icons/ri";
import { HiMiniInformationCircle } from "react-icons/hi2";

// Components :
import { Header } from "../Components";

const getSideBarData = ({ label, key, icon, children, disabled }) => {
    return { key, icon, children, label, disabled };
};


const AppRoutesList = [
    getSideBarData({ label: "Dashboard", key: "/dashboard", icon: <BiSolidDashboard className="icon" /> }),
    getSideBarData({
        label: "Product Catalog",
        key: "/product-catalog",
        icon: <FaProductHunt className="icon" />,
        children: [
            getSideBarData({ label: "My Articles", key: "/my-articles", }),
            getSideBarData({ label: "Add Items Per Piece", key: "/add-articles", }),
        ],
    }),
    getSideBarData({
        label: "Catalogs",
        key: "/catelogs",
        icon: <GrCatalog className="icon" />,
        children: [
            getSideBarData({ label: "Attributes", key: "/attributes", }),
            getSideBarData({ label: "Categories", key: "/categories", }),
        ],
    }),
    getSideBarData({ label: "Orders", key: "/campaigns", icon: <BsFlagFill className="icon" />, disabled: true }),
    getSideBarData({ label: "BackOrders", key: "/employees", icon: <PiUsersThreeFill className="icon" />, disabled: true }),
    getSideBarData({ label: "Finance", key: "/supplier-sites", icon: <BiSolidHomeSmile className="icon" />, disabled: true }),
    getSideBarData({ label: "Pricing", key: "/view-Jobs", icon: <MdPersonSearch className="icon" />, disabled: true }),
    getSideBarData({ label: "Manage Inventory", key: "/view-History", icon: <RiFolderHistoryFill className="icon" />, disabled: true }),
    getSideBarData({ label: "Report", key: "/payments", icon: <GiReceiveMoney className="icon" />, disabled: true }),
    getSideBarData({ label: "My Shop", key: "/tutorial-videos", icon: <RiVideoFill className="icon" />, disabled: true }),
    getSideBarData({ label: "Drop Shipping", key: "/about", icon: <HiMiniInformationCircle className="icon" />, disabled: true }),
    getSideBarData({ label: "App Store", key: "/help-support", icon: <BiSupport className="icon" />, disabled: true }),
    getSideBarData({ label: "Admin", key: "/setting", icon: <BiSolidCog className="icon" />, disabled: true }),
];

export { AppRoutesList };
