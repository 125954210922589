import { lazy } from "react";


const APPRoute = [
    { path: "/dashboard", element: lazy(() => import("../Pages/Dashboard/Dashboard")) },
    { path: "/categories", element: lazy(() => import("../Pages/Admin/Categories/Categories")) },
    { path: "/attributes", element: lazy(() => import("../Pages/Admin/Attributes/Attributes")) },
    { path: "/my-articles", element: lazy(() => import("../Pages/Catalog/MyArticles/MyArticles")) },
    { path: "/edit-article/:id", element: lazy(() => import("../Pages/Catalog/MyArticles/EditArticle/EditArticle")) },
    { path: "/add-articles", element: lazy(() => import("../Pages/Catalog/ArticleAdd/ArticleAdd")) },
];

export default APPRoute;
