import React from 'react'

// CSS :
import './DashboardCard.scss'


const Cards = (props) => {
    const { name, num, img, boxbgImg, bgColor } = props;
    return (
        <>
            <div
                className="dash-card-container"
                style={{
                    background: `url(${boxbgImg}) right top no-repeat, linear-gradient(${bgColor})`
                }}>
                <div className="dash-card-item" >
                    <img src={img} alt="INTERNET ERROR" />
                    <h2>{name}</h2>
                </div>
                <h5>{num}</h5>
            </div>
        </>
    )
}

export default Cards