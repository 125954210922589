import React from "react";

const PreLoader = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "-webkit-fill-available",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>Loading...</div>
    </div>
  );
};

export default PreLoader;
