import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';

// ANT-D :
import { Input, Form, Button, } from 'antd'

// Components :
import { TagLine } from '../Components';
import { PrimaryHeading } from '../../../Components/Common';

// Redux :
import { useDispatch, useSelector } from 'react-redux';

// Services
import AuthService from '../../../Services/auth.services';

// CSS :
import './Register.scss'
import 'react-phone-input-2/lib/style.css'


const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { isLoading } = useSelector((state) => state.signUp);

  useEffect(() => {
    document.title = 'Jaydad | Register';
  }, [dispatch])

  const handleCancel = () => {
    form.resetFields();
    navigate('/login')
  }
  const hanldeRegister = async (values) => {
    AuthService.register(values, dispatch, navigate);
  }

  return (
    <div className="sign-up-container">
      <div className="tag-line"><TagLine /></div>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        className="sign-up-form"
        onFinish={hanldeRegister}
      >
        <div className="form">
          <div className="register-title">
            <PrimaryHeading PrimaryHeading="Registration" />
          </div>

          <div className="flex-details">
            <Form.Item label="First Name" name="firstName" className="form-field" rules={[{ required: true }]}>
              <Input type='text' placeholder='First Name' />
            </Form.Item>

            <Form.Item label="Last Name" name="lastName" className="form-field" rules={[{ required: true }]}>
              <Input type='text' placeholder='Last Name' />
            </Form.Item>

            <Form.Item label="Email" name="email" className="form-field" rules={[{ required: true, }]}>
              <Input type='text' placeholder='Email' />
            </Form.Item>

            <Form.Item label="Address" name="address" className="form-field" rules={[{ required: true, }]}>
              <Input type='text' placeholder='Address' />
            </Form.Item>

            <Form.Item label="Mobile Number" name="phone" className="form-field" rules={[{ required: true }]}>
              <PhoneInput onlyCountries={['pk']} country='pk' inputProps={{ placeholder: '' }} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="Store Name" name="storeName" className="form-field" rules={[{ required: true }]}>
              <Input type='text' placeholder='Store Name' />
            </Form.Item>

            <Form.Item label="Postal Code" name="postalCode" className="form-field">
              <Input placeholder='Postal Code' />
            </Form.Item>

            <Form.Item label="Country" name="country" className="form-field" rules={[{ required: true }]}>
              <Input placeholder='Country' />
            </Form.Item>
            <Form.Item label="Province" name="province" className="form-field" rules={[{ required: true }]}>
              <Input placeholder='Province' />
            </Form.Item>

            <Form.Item label="City" name="city" className="form-field" rules={[{ required: true }]}>
              <Input type='text' placeholder='City' />
            </Form.Item>

            <Form.Item label="Password" name="password" className="form-field"
              rules={[
                { required: true, },
                { min: 4, message: 'Password must contain at least 4 characters with at last' },
              ]}
            >
              <Input.Password placeholder='Password' />
            </Form.Item>

            <Form.Item label="Confirm Password" name="confirmPassword" dependencies={['password']}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The confirm password that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder='Password' />
            </Form.Item>

            <div className='register-buttons'>
              <Button size='medium' type="primary" className='secondary-button' loading={isLoading} htmlType="submit" style={{ width: '100%' }} >
                Register
              </Button>
            </div>
            <div className="register-content" style={{ marginTop: "10px" }}>
              <a onClick={handleCancel}>Already have an Account?</a>
            </div>
          </div>
        </div>
      </Form >
    </div >
  )
}

export default Register
