import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// ANT-D :
import { Button, Form, Input } from "antd";

// Components :
import { TagLine } from '../Components';

// Services :
import AuthService from "../../../Services/auth.services";

// Redux :
import { useDispatch, useSelector } from "react-redux";

// CSS :
import "./Login.scss";


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { isLoading } = useSelector((state) => state.signIn);

  useEffect(() => {
    document.title = 'Jaydad | Login';
  }, [dispatch])

  const handleLogin = (values) => {
    AuthService.login(values, dispatch, navigate);
  };

  return (
    <div className="login-container">
      <TagLine />
      <div className="login_form_box">
        <Form
          form={form}
          name="basic"
          layout="vertical"
          className="login-form"
          onFinish={handleLogin}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="email"
            className="form-field"
            rules={[{ required: true, type: "email", }]}
          >
            <Input size="large" autoFocus placeholder='Email address or phone number' />
          </Form.Item>

          <Form.Item
            name="password"
            className="form-field"
            rules={[{ required: true }]}
          >
            <Input.Password size="large" placeholder='Password' />
          </Form.Item>

          <Form.Item
            className="form-field"
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className="primary-btn"
            >
              Log in
            </Button>
          </Form.Item>
          <Link to="/forgot-password">Forgot Password?</Link>
          <Form.Item>
            <Button
              type="primary"
              className="register-btn"
              onClick={() => navigate('/register')}
            >
              Create new account
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
