import React from "react";

// Components :
import { UserInfo } from "../UserInfo";

// Redux :
import { useSelector } from "react-redux";

// CSS :
import "./Header.scss";
import { FaGreaterThan } from "react-icons/fa";

const Header = () => {
  const { user } = useSelector((state) => state.signIn);
  const { pageTitle } = useSelector((state) => state.common);

  let AccountType =
    user?.userInfo?.accountType?.charAt(0).toUpperCase() +
    user?.userInfo?.accountType?.slice(1)?.toLowerCase();

  return (
    <div className="dashboard-header">
      <div className="header-heading">
        <div className="heading">{AccountType} Panel</div>

        <FaGreaterThan className="icon" />

        <span>{pageTitle}</span>
      </div>
      <div className="auth-button">
        <UserInfo />
      </div>
    </div>
  );
};

export default Header;
