import React, { useEffect } from "react";

// ANT-D :
import { Col, Row } from "antd";

// Components :
import { DashboardCard } from "./Components";

// DashBoard Cards Images :
import manageOrder from "../../Assets/Images/dash-cart-icon.png";
import returnBox from "../../Assets/Images/dash-return-icon.png";
import customerBox from "../../Assets/Images/dash-messages-icon.png";
import performance from "../../Assets/Images/dash-performance-icon.png";

// DashBoard Cards Background Images :
import manageOrderbg from "../../Assets/Images/dash-cart-icon-bg.png";
import returnBoxbg from "../../Assets/Images/dash-return-icon-bg.png";
import customerBoxbg from "../../Assets/Images/dash-messages-icon-bg.png";
import performancebg from "../../Assets/Images/dash-performance-icon-bg.png";
import orderServices from "../../Services/Catalog/order.service";
import { useDispatch, useSelector } from "react-redux";


const DashboadCards = () => {
  const dispatch = useDispatch();
  const { orderList } = useSelector((state) => state.order);

  useEffect(() => {
    orderServices.getOrders(dispatch);
  }, [])

  return (
    <Row gutter={[15, 15]}>
      <Col xl={6} lg={12} md={12} sm={24} xs={24} >
        <DashboardCard
          name={"Manage Orders"}
          num={18}
          img={manageOrder}
          boxbgImg={manageOrderbg}
          bgColor="90deg, rgb(149, 219, 252) 0%, rgb(194, 235, 253) 100%"
        />
      </Col>
      <Col xl={6} lg={12} md={12} sm={24} xs={24} >
        <DashboardCard
          name={"Returns"}
          num={95800}
          img={returnBox}
          boxbgImg={returnBoxbg}
          bgColor="90deg, rgb(254, 180, 198) 0%, rgb(253, 205, 217) 100%"
        />
      </Col>
      <Col xl={6} lg={12} md={12} sm={24} xs={24} >
        <DashboardCard
          name={"Customer Questions"}
          num={95800}
          img={customerBox}
          boxbgImg={customerBoxbg}
          bgColor="90deg, rgb(166, 228, 216) 0%, rgb(203, 246, 237) 100%"
        />
      </Col>
      <Col xl={6} lg={12} md={12} sm={24} xs={24} >
        <DashboardCard
          name={"Performance"}
          num={95800}
          img={performance}
          boxbgImg={performancebg}
          bgColor="90deg, rgb(253, 233, 206) 0%, rgb(251, 231, 202) 100%"
        />
      </Col>
    </Row>
  );
};

export default DashboadCards;
