import React from "react";
import { FaCaretDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

// ANT-D :
import { Avatar, Dropdown, Space, Typography, message } from "antd";
import { CaretDownOutlined, PoweroffOutlined } from "@ant-design/icons";

// Services :
import AuthService from "../../Services/auth.services";

// Redux :
import { useSelector } from "react-redux";

// CSS :
import "./UserInfo.scss";

const UserInfo = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.signIn);

  const onLogoutClick = () => {
    AuthService.logout(navigate);
  };

  const items = [
    {
      label: (
        <span
          className="gx-d-flex gx-justify-content-between gx-align-items-center"
          //  onClick={() => history.push("/header/my-account")}
        >
          {/* <FontAwesomeIcon icon={faUser} /> */}
          My Account
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span
          className="gx-d-flex gx-align-items-center"
          onClick={onLogoutClick}
        >
          {/* <PoweroffOutlined className="gx-mr-3" /> */}
          Sign Out
        </span>
      ),
      key: "2",
    },
  ];

  const src =
    "https://scontent.flhe4-2.fna.fbcdn.net/v/t39.30808-6/336767355_1355329388374360_9164915824162173197_n.jpg?stp=dst-jpg_p526x296&_nc_cat=111&ccb=1-7&_nc_sid=dd5e9f&_nc_eui2=AeFmej6Fx1yIi6-FRu7qpVC52ypVXSYvChbbKlVdJi8KFkbBhzTuaIlf-i2wpVdl36PsXi8A0MWd5anMt85WpXp2&_nc_ohc=iGO6gq7VhK8AX8GTsAC&_nc_zt=23&_nc_ht=scontent.flhe4-2.fna&oh=00_AfCrje87bqdg8B6AsnH_L42IlSl3UEvIX1pzL41ZWDDMtA&oe=6571882D";

  return (
    <div className="user-info-section">
      <div className="flex-user-info">
        <Avatar
          src={src}
          className="gx-avatar gx-pointer"
          alt="INTERNET ERROR"
        />
        <div>
          <Dropdown
            arrow
            placement="bottomRight"
            menu={{ items }}
            trigger={["click"]}
          >
            <Typography.Link>
              <Space className="account-name">
                <b>{user?.userInfo.firstName}</b>
                <FaCaretDown />
              </Space>
            </Typography.Link>
          </Dropdown>
          <div className="account-type">
            {/* {authUser?.accountType?.charAt(0).toUpperCase() + authUser?.accountType?.slice(1)?.toLowerCase()} */}
            {user?.userInfo?.accountType?.charAt(0).toUpperCase() +
              user?.userInfo?.accountType?.slice(1)?.toLowerCase()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
