import { toast } from 'react-toastify';
import http from '../http.service';
import Promisable from '../promisable.service';
import { categoryActions } from '../../Redux/Admin/CategorySlice';


const getHeaders = () => {
    const token = localStorage.getItem("token");
    const headers = {
        Authorization: `${token}`,
    };
    return headers;
};
const categoryService = {
    getCategories: async (dispatch) => {
        let headers = getHeaders();
        dispatch(categoryActions.setIsLoading(true))
        let URL = `/products/api/v1/categories/get-parent-categories`;
        const [success, error] = await Promisable.asPromise(http.get(URL, headers));

        if (success) {
            const successData = success.data.responseData.data;
            dispatch(categoryActions.setCategoriesList(successData))
            dispatch(categoryActions.setIsLoading(false))
        }
        if (error) {
            console.log("============>", error);
            dispatch(categoryActions.setIsLoading(false))
        }
        return [success, error];
    },
    getCategoryAttributes: async (dispatch, id) => {
        let headers = getHeaders();
        let URL = `/products/api/v1/categories/get-category-attributes?categoryId=${id}`;
        const [success, error] = await Promisable.asPromise(http.get(URL, headers));

        if (success) {
            const successData = success.data.responseData.data;
            dispatch(categoryActions.setCategoryattribute(successData));
        }
        if (error) {
            console.log("============>", error);
        }
        return [success, error];
    },
    addCategory: async (dispatch, values) => {
        let headers = getHeaders();
        dispatch(categoryActions.setIsCreateUpdateLoading(true))
        let URL = `/products/api/v1/categories/create-category`;
        const [success, error] = await Promisable.asPromise(http.post(URL, values, headers));

        if (success) {
            console.log("=======>", success.data.message)
            toast.success(success.data.message);
            const successData = success.data.responseData.data?.catAttributeList;
            dispatch(categoryActions.setCategoriesList(successData))
            dispatch(categoryActions.setIsCreateUpdateLoading(false))
        }
        if (error) {
            console.log("============>", error);
            dispatch(categoryActions.setIsCreateUpdateLoading(false))
        }
        return [success, error];
    },
    editProdCategory: async (dispatch, catId, prodId,) => {
        let headers = getHeaders();
        dispatch(categoryActions.setIsCreateUpdateLoading(true))
        let URL = `/products/api/v1/products/update-product-category?categoryId=${catId}&id=${prodId}`;
        const [success, error] = await Promisable.asPromise(http.post(URL, headers));

        if (success) {
            toast.success(success.data.message);
            dispatch(categoryActions.setIsCreateUpdateLoading(false))
        }
        if (error) {
            dispatch(categoryActions.setIsCreateUpdateLoading(false))
        }
        return [success, error];
    },
    getPublicCategories: async (dispatch, id) => {
        let headers = getHeaders();
        dispatch(categoryActions.setIsLoading(true));
        let URL = `/products/api/v1/categories/get-public-categories?categoryId=${id}`;
        const [success, error] = await Promisable.asPromise(http.get(URL, headers));

        if (success) {
            const successData = success.data.responseData.data;
            dispatch(categoryActions.setPublicCategories(successData));
            dispatch(categoryActions.setIsLoading(false));
        }
        if (error) {
            const errorMessage = error.response.data.error;
            toast.error(errorMessage)
            dispatch(categoryActions.setIsLoading(false));
        }
        return [success, error];
    },

    // shoaib code
    getPublicChildCategories: async (dispatch, categoryId) => {
        let headers = getHeaders();
        dispatch(categoryActions.setIsLoading(true));
        let URL = `/products/api/v1/categories/get-public-categories?categoryId=${categoryId}`;
        const [success, error] = await Promisable.asPromise(http.get(URL, headers));

        if (success) {
            const successData = success.data.responseData.data;
            dispatch(categoryActions.setPublicCategories(successData));
            dispatch(categoryActions.setIsLoading(false));
        }
        if (error) {
            const errorMessage = error.response.data.error;
            toast.error(errorMessage)
            dispatch(categoryActions.setIsLoading(false));
        }
        return [success, error];
    },
}
export default categoryService;