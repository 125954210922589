import React from "react";
import { AppRoutes } from "./Routes";

// CSS :
import "./App.scss";
import './AntDesign.scss'


function App() {
  return (<AppRoutes />);
}

export default App;
