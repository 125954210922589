const FieldTypeArray = [
    { colSize: 3, value: 3 },
    { colSize: 4, value: 4 },
    { colSize: 5, value: 5 },
    { colSize: 6, value: 6 },
    { colSize: 7, value: 7 },
    { colSize: 8, value: 8 },
    { colSize: 9, value: 9 },
    { colSize: 10, value: 10 },
    { colSize: 11, value: 11 },
    { colSize: 12, value: 12 },
    { colSize: 13, value: 13 },
    { colSize: 14, value: 14 },
    { colSize: 15, value: 15 },
    { colSize: 16, value: 16 },
    { colSize: 17, value: 17 },
    { colSize: 18, value: 18 },
    { colSize: 19, value: 19 },
    { colSize: 20, value: 20 },
    { colSize: 21, value: 21 },
    { colSize: 22, value: 22 },
    { colSize: 23, value: 23 },
    { colSize: 24, value: 24 },
];
export { FieldTypeArray }